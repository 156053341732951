@import "fonts";
@import "variables";

//--------------------------------------------------------------------------------------------
//	  Libs styles
//--------------------------------------------------------------------------------------------

@import "vendors/bootstrap";
@import "../libs/swiper/swiper";
@import "../libs/fullpage/fullpage";
@import "../libs/aos/sass/aos";
@import "../libs/fancybox/_jquery.fancybox";

//--------------------------------------------------------------------------------------------
//	  Project styles
//--------------------------------------------------------------------------------------------

@import "modules/_modules";
@import "layout/_layout";
@import "base";
@import "utilities/_utilities";
