$font-path: '../fonts/sourcesanspro/';

@font-face {
    font-family: 'Source Sans Pro';
    src: url($font-path + 'sourcesanspro.eot');
    src: url($font-path + 'sourcesanspro.eot?#iefix') format('embedded-opentype'), url($font-path + 'sourcesanspro.woff') format('woff'), url($font-path + 'sourcesanspro.ttf') format('truetype');
    font-weight: 400;
    font-style: normal; }

@font-face {
    font-family: 'Source Sans Pro';
    src: url($font-path + 'sourcesanspro_semibold.eot');
    src: url($font-path + 'sourcesanspro_semibold.eot?#iefix') format('embedded-opentype'), url($font-path + 'sourcesanspro_semibold.woff') format('woff'), url($font-path + 'sourcesanspro_semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal; }

@font-face {
    font-family: 'Source Sans Pro';
    src: url($font-path + 'sourcesanspro_bold.eot');
    src: url($font-path + 'sourcesanspro_bold.eot?#iefix') format('embedded-opentype'), url($font-path + 'sourcesanspro_bold.woff') format('woff'), url($font-path + 'sourcesanspro_bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal; }
