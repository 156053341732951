.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	color: $body-color;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
	@include transition($btn-transition);

	@include hover {
	  color: $body-color;
	  text-decoration: none; }

	&.disabled,
	&:disabled {
	  opacity: $btn-disabled-opacity;
	  pointer-events: none; }

	&-anim {
		padding: 1px 0;
		overflow: hidden;
		position: relative;
		&__inner {
			display: block;
			position: relative;
			border-bottom: 1px solid $weed;
			border-top: 1px solid $weed;
			&::after,
			&::before {
				height: 0;
				content: '';
				position: absolute;
				background-color: $lime;
				-webkit-transition: height .3s ease-out 150ms;
				-o-transition: height .3s ease-out 150ms;
				transition: height .3s ease-out 150ms; }
			&::before {
				left: 0;
				top: -1px;
				width: 3px; }
			&::after {
				right: 0;
				bottom: -1px;
				width: 1px; }
			&:hover {
				&::after,
				&::before {
					height: calc(100% + 2px); } } }
		&::after,
		&::before {
			content: '';
			width: 47px;
			height: 3px;
			z-index: 1;
			position: absolute;
			-webkit-transition: .3s left .3s ease-out,right .3s ease-out;
			-o-transition: .3s left .3s ease-out,right .3s ease-out;
			transition: .3s left .3s ease-out,right .3s ease-out;
			background-color: $lime; }
		&::before {
			top: 0;
			left: 29px; }
		&::after {
			bottom: 0;
			right: 29px; }

		&:hover {
			&:after,
			&:before {
				transition: left .3s ease-out,right .3s ease-out; }
			&:after {
				right: -47px; }
			&::before {
				left: -47px; } }

		&_white {
			.btn-anim__inner {
				color: #fff;
				border-bottom: 1px solid #fff;
				border-top: 1px solid #fff; } } }

	&-bd-gray {
		color: $gray9;
		border: 1px solid $gray9;
		&:hover,
		&:focus {
			border-color: $lime; } } }




// .btn-lg
// 	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg)

// .btn-sm
.btn-sm {
	font-size: 12px;
	text-transform: uppercase;
	padding: $btn-padding-y-sm $btn-padding-x-sm;
	letter-spacing: .9px; }

// .btn-xl {
// 	padding: 20px 40px;
// }

// .btn-xs {
// 	padding: 4px 8px;
// 	font-size: 14px;
// }


@each $color, $value in $color-custom {

	.btn-bg-#{$color} {
		color: #fff;
		background-color: $value;
		border-color: $value;

		&:hover,
		&:focus {
			color: #fff;
			background-color: darken($value, 5%);
			border-color: darken($value, 5%); } }


	.btn-bd-#{$color} {
		color: $value;
		background-color: transparent;
		border-color: $value;
		position: relative;
		z-index: 1;

		&:after {
			content: '';
			width: 100%;
			height: 0;
			background-color: $value;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;
			transition: all .3s;
			opacity: 0; }

		&:hover,
		&:focus {
			color: #fff;
			border-color: $value;

			&:after {
				height: 100%;
				opacity: 1; } }

		&.active {
			background-color: $value;
			box-shadow: 0px 5px 30px rgba($value, 0.4);
			color: #fff; } } }
