.front {
    &-slider {
        &__title {
            font-size: 64px; }
        &__text {
            font-size: 33px;
            min-height: 92px; }
        &__nav {
            position: absolute;
            bottom: 96px;
            z-index: 1;
            left: 0;
            width: 100%; }
        &-item {
            min-height: 100vh;
            height: 100%;
            padding-top: 240px;
            padding-bottom: 150px;
            &::after {
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                content: '';
                background-color: rgba(255,255,255,.53);
                position: absolute; }
            .container {
                z-index: 1;
                position: relative; } } } }

@media (max-width: 1599px) {
    .front-slider-item {
        padding-top: 200px;
        padding-bottom: 130px; }
    .front-slider__nav {
        bottom: 80px; }
    .front-slider__title {
        font-size: 50px; }
    .front-slider__text {
        min-height: 0;
        font-size: 28px; }
    .card-top {
        height: 318px; } }

@include media-breakpoint-down(lg) {
    .front-slider__title {
        font-size: 40px;
        line-height: 1.3 !important; }
    .front-slider__text {
        font-size: 26px; }
    .front-slider__nav {
        bottom: 40px; }
    .front-slider-item {
        padding-top: 140px;
        padding-bottom: 100px; }
    .front-slider__title {
        font-size: 36px; }
    .front-slider__text {
        font-size: 22px; } }

@include media-breakpoint-down(md) {
    .front-slider__text {
        font-size: 22px; }
    .front-slider__nav {
        bottom: 25px; }
    .front-slider-item {
        padding-top: 120px;
        padding-bottom: 80px; }
    .front-slider__title {
        font-size: 32px; } }
@include media-breakpoint-down(sm) {
    .front-slider__title {
        font-size: 28px; }
    .front-slider__text {
        font-size: 20px; } }

@include media-breakpoint-down(xs) {
    .front-slider__title {
        font-size: 26px; }
    .front-slider__text {
        font-size: 18px; }
    .front-slider-item {
        padding-top: 90px;
        padding-bottom: 70px; } }
