table {
	width: 100%;

	th,
	td {
		vertical-align: middle;
		padding: 1rem; }

	thead {
		th {} }

	tbody tr:nth-of-type(even) {} }


.table-responsive {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-down($breakpoint) {
				display: block;
				width: 100%;
				overflow-x: auto;
				-webkit-overflow-scrolling: touch;
				margin-bottom: 32px;

				table {
					margin-bottom: 0; } } } } }


//  Responsive Media Querries  ----------------------------------------------------------------------

@include media-breakpoint-down(md) {

	.table td, .table th {} }


@include media-breakpoint-down(xs) {

	.table-label {

		thead {
			display: none; }

		tbody {

			tr {

				td, th {

					&:before {
						content: attr(data-label);
						display: block;
						font-size: 1rem;
						font-weight: 600;
						margin-bottom: 0.25rem; } } } } } }
