.b-service {
    &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        background-color: rgba(34, 34, 34, .5); } }

.service {
    &-row {}
    &-link {
        display: block;
        text-decoration: none !important;
        &__inner {
            border-left: 1px solid rgba(229, 229, 229, 0.2);
            border-top: 1px solid rgba(229, 229, 229, 0.2);
            border-bottom: 1px solid rgba(229, 229, 229, 0.2);
            &::after,
            &::before {
                content: '';
                width: 64px;
                height: 3px;
                z-index: 1;
                position: absolute;
                -webkit-transition: left .3s ease-out,right .3s ease-out .3s, right .3s ease-out;
                -o-transition: left .3s ease-out,right .3s ease-out .3s, right .3s ease-out;
                transition: left .3s ease-out,right .3s ease-out .3s, right .3s ease-out;
                background-color: $lime; }
            &::before {
                top: -2px;
                left: 50%;
                margin-left: -32px; }
            &::after {
                bottom: -2px;
                right: 50%;
                margin-right: -32px; }

            &:hover {
                &:after,
                &:before {
                    transition: left .3s ease-out,right .3s ease-out; }
                &:after {
                    right: -32px; }
                &::before {
                    left: -32px; } } }
        &::after,
        &::before {
            height: 0;
            content: '';
            position: absolute;
            background-color: $lime;
            -webkit-transition: height .3s ease-out 150ms;
            -o-transition: height .3s ease-out 150ms;
            transition: height .3s ease-out 150ms; }
        &::before {
            left: 0;
            top: -1px;
            width: 3px; }
        &::after {
            right: 0;
            bottom: -1px;
            width: 1px; }
        &:hover {
            &::after,
            &::before {
                height: calc(100% + 2px); } }
        &-col {
            &:last-child .service-link {
                border-right: 1px solid rgba(229, 229, 229, 0.2); } }
        &__text {
            color: $lime !important; }
        &__title {
            color: #fff;
            font-size: 36px; } } }

@include media-breakpoint-up(lg) {
    .service {
        &-row {
            height: 212px; } } }
@include media-breakpoint-down(lg) {
    .service-link__title {
        font-size: 30px; }
    .service-link {
        border-right: 1px solid #e5e5e533; } }
@include media-breakpoint-down(md) {
    .service-link__title {
        font-size: 26px; } }
@include media-breakpoint-down(sm);

@include media-breakpoint-down(xs) {
    .service-link {
        max-width: 300px; }
    .service-link__title {
        font-size: 24px; } }
