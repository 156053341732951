.modal-close {
	display: block;
	background: none;
	border: 0;
	padding: 0;
	line-height: 1;
	color: #fff;
	transition: all .3s;
	position: absolute;
	top: -28px;
	right: 0px;
	z-index: 1;

	.icon {
		width: 20px;
		height: 20px; } }



//  Responsive Media Querries  -----------------------------------------------------------

@include media-breakpoint-down(xs) {

	.modal-body,
	.modal-header {
        padding-left: 16px;
        padding-right: 16px; } }
