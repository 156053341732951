.action {
    &-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 38px 48px;
        &-text {
            flex: 1 0 auto;
            margin-top: 22px;
            line-height: 1.87; }
        &-more {
            font-size: 14px;
            font-weight: 600; }
        &-name,
        &-more {
            text-decoration: none !important;
            &:hover,
            &:focus {
                color: $lime !important; } } }
    &-link {
        width: 310px;
        height: 310px;
        min-height: 100%;
        display: block;
        position: relative;
        &-bg {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: all .3s;
            position: absolute; }
        &:hover,
        &:focus {
            .action-link-bg {
                transform: scale(1.1); } } } }

@media (min-width: 1600px) {
    .docs-nav {
        margin-right: 60px; } }
@media (max-width: 1699px) {
    .action-body {
        padding: 20px 30px; } }
@media (max-width: 1599px) {
    .action-link {
        width: 260px; } }

@include media-breakpoint-up(lg);
@include media-breakpoint-down(lg) {
    .action-body-text {
        margin-top: 15px; }
    .action-link {
        width: 310px; } }
@include media-breakpoint-down(sm) {
    .action-link {
        width: 260px;
        height: 260px; }
    .action-body {
        padding: 20px 0 20px 20px; } }
@include media-breakpoint-down(xs) {
    .action {
        max-width: 270px;
        margin: 0 auto;
        &-body {
            padding-left: 0; }
        &-link {
            width: 100%; } } }
