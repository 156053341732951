//--------------------------------------------------------------------------------------------
// THEME
//--------------------------------------------------------------------------------------------

// Colors ------------------------------------------------------------------------------------

$white:  #fff;
$black: #000;
$red:    red;
$lime:   #3BD142;
$weed:   #0A2A0B;
$gray:   #4F4F4F;
$gray9:   #999;
$gray-light: #bdbdbd;
$blue:   blue;
$sky: #f8fcfc;
$brown:   #3F1C00;
$yellow: #FFCC16;

$color-custom: (b: $black, r: $red, bl: $blue, y: $yellow, g: $weed, brown: $brown, weed: $weed, lime: $lime, w: $white, sky: $sky, g-9: $gray9);

// Grid breakpoints ---------------------------------------------------------------------------

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1360px);

// Grid containers ----------------------------------------------------------------------------

$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1200px);

// -------------------------------------------------------------------------------------------

$spacer-custom: ( 0, 1, 2, 5, 10, 12, 13, 14, 15, 16, 17, 18, 20, 23, 25, 27, 28, 30, 33, 35, 36, 38, 40, 45, 49, 50, 53, 54, 55, 60, 65, 67, 68, 70, 73, 75, 80, 85, 92, 95, 100, 113, 119, 120, 155 );

$width-height: 	( 10, 20 );

$width: 		    ( 10, 20 );

$height: 		    ( 10, 20 );

$gutter:  		  ( 0, 2, 4, 5, 10, 16, 18, 20, 25, 40, 60 );

$font-size: 	  ( 8, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 27, 28, 30, 32, 33, 34, 36, 38, 40, 44, 48, 56, 60, 64, 72 );

// -------------------------------------------------------------------------------------------

$body-bg:                      $white;
$body-color:               	   $gray;

$link-color:                   #0A2A0B;

$border-color:                 $gray;
$input-border-color:           $gray;
$hr-border-color:              $gray;

$img-path:                     '../img/';

// Typography --------------------------------------------------------------------------------

$font-family-base:            'Source Sans Pro', Arial, sans-serif;
$font-size-base:              1rem;
$font-weight-base:            400;
$line-height-base:            1.5;

$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$paragraph-margin-bottom:     1.5rem;

// -------------------------------------------------------------------------------------------

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.375;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base * 1.125;

$headings-font-weight:        700;
$headings-line-height:        1.3;
$headings-color:              $black;

$headings-margin-bottom:      1.25rem;
$headings-font-family:        null;

// Gutters -----------------------------------------------------------------------------------

$grid-gutter-width:            30px;

// Borders -----------------------------------------------------------------------------------

$border-width:                1px;
$border-color:                $gray;

$border-radius:               5px;
$border-radius-lg:            10px;
$border-radius-sm:            2px;

// Buttons -----------------------------------------------------------------------------------

$btn-padding-y:               12px;
$btn-padding-x:               32px;
$btn-font-size:               18px;
$btn-line-height:             1;
$btn-border-width:            1px;
$btn-font-weight:             700;
$btn-border-radius:           0;

$btn-padding-y-sm:            10px;
$btn-padding-x-sm:            16px;
$btn-font-size-sm:            12px;
$btn-line-height-sm:          $btn-line-height;
$btn-border-radius-sm:        0;

$btn-padding-y-lg:            1rem;
$btn-padding-x-lg:            2rem;
$btn-font-size-lg:            1rem;
$btn-line-height-lg:          $btn-line-height;
$btn-border-radius-lg:        $border-radius;
$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;

$btn-disabled-opacity:        .3;

// Forms -------------------------------------------------------------------------------------

$label-margin-bottom:                   .25rem;

$input-padding-y:                       .5rem;
$input-padding-x:                       .75rem;

$input-bg:                              $white;
$input-color:                           $black;
$input-border-width:                    1px;
$input-border-color:                    $border-color;
$input-focus-border-color:              $gray-light;
$input-placeholder-color:               $gray-light;

$input-height-border:                   $input-border-width * 2;

$input-padding-y-lg:                    0.75rem;
$input-padding-x-lg:                    1rem;
$input-font-size-lg:                    $font-size-base;
$input-line-height-lg:                  $line-height-base;
$input-border-radius-lg:                $border-radius;
$input-height-lg:                       calc(#{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2} + #{$input-height-border});

// Table -------------------------------------------------------------------------------------

$table-cell-padding:           1rem .75rem;
$table-border-color:           $border-color;
$table-vertical-align:         middle;
$table-head-weight:            400;
$table-head-vertical-align:    middle;
$table-def-head-color:         $gray;
$table-head-wrap:              nowrap;

// DropDown -----------------------------------------------------------------------------------

// $dropdown-link-color:               $c-db
// $dropdown-link-hover-color:         $c-b
// $dropdown-link-hover-bg:            transparent

// $dropdown-link-active-color:        $c-b
// $dropdown-link-active-bg:           transparent
// $dropdown-item-padding-y:           .5rem
// $dropdown-item-padding-x:           1rem


// $caret-width:                 0.325rem
// $caret-vertical-align:        middle
// $caret-spacing:               .75rem

// Navs ---------------------------------------------------------------------------------------

// $nav-pills-border-radius:            0
// $nav-link-padding-y:                .9rem
// $nav-link-padding-x:                1rem

// $nav-tabs-border-color:             $border-color
// $nav-tabs-link-active-color:        $c-b
// $nav-tabs-link-hover-border-color:  $c-b
// $nav-tabs-link-active-border-color: $c-b

// Modals -------------------------------------------------------------------------------------

$modal-inner-padding:               3rem;
// $modal-sm:                          350px
$modal-header-padding:              3rem;

// Other --------------------------------------------------------------------------------------

$enable-rounded:                    true;
