.b-footer {
    padding-top: 88px;
    padding-bottom: 65px;
    background-color: $sky; }

.footer {
    &-icon {
        width: 20px; }
    &-logo {
        &-img {
            height: 54px; } }
    &-nav {
        columns: 2;
        column-gap: 60px;
        &__link {
            font-size: 14px;
            padding-left: 15px;
            text-decoration: none !important;
            background: url(../img/icon_chevron_right.svg) no-repeat left 5px;
            background-size: 5px auto;
            &:hover,
            &:focus {
                color: $lime;
                background-image: url('../img/icon_chevron_right_lime.svg'); } } } }


@include media-breakpoint-down(lg) {
    .footer-btn {
        font-size: 16px; }
    .footer-logo-img {
        height: 39px; }
    .b-footer {
        padding-top: 60px;
        padding-bottom: 35px; } }

@include media-breakpoint-down(md);
@include media-breakpoint-down(sm) {
    .b-footer {
        padding-top: 45px;
        padding-bottom: 25px; } }
@include media-breakpoint-down(xs) {
    .b-footer {
        padding-top: 35px;
        padding-bottom: 15px; } }
