.modal {
    &-content {
        position: relative;
        border: none;
        border-radius: 0;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
        &_bg {
            &:after {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                position: absolute;
                background-color: rgba(34, 34, 34, .7); } } }
    .close {
        right: 22px;
        top: 23px;
        width: 16px;
        height: 16px;
        z-index: 2;
        border: 0;
        background: url('../img/icon_close.svg') no-repeat center;
        background-size: 16px 16px;
        position: absolute;
        font-size: 0;
        &_weed {
            background-image: url('../img/icon_close_weed.svg'); }
        &:hover {
            background-image: url('../img/icon_close_lime.svg'); } } }

@include media-breakpoint-up(lg) {
    .modal {
        &-medium {
            max-width: 1014px; } } }

@include media-breakpoint-down(xs);
@include media-breakpoint-down(xs);
