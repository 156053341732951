.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
        display: block;

        &:hover,
        &:focus {
            color: $red;
            text-decoration: none; } }

    .active > .nav-link,
    .active.nav-link {
        color: $red; } }


//  Responsive Media Querries  ------------------------------------------------------

@include media-breakpoint-down(lg);


@include media-breakpoint-down(md);

@include media-breakpoint-down(sm);

@include media-breakpoint-down(xs);
