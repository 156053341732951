.form-group {
    margin-bottom: 16px; }

.input {
    height: 55px;
    display: block;
    width: 100%;
    border: 1px solid #E1E7EF;
    padding: 10px 20px 2px;
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 400;
    color: $body-color;
    &-label {
        top: 50%;
        left: 20px;
        right: 20px;
        font-size: 14px;
        color: $weed;
        transform: translateY(-50%);
        position: absolute;
        transition: all .3s;
        line-height: 1.1; }
    &-wrap {
        .input {
            background: transparent !important;
            &.input-has-val,
            &:focus {
                & + .input-label {
                    top: 7px;
                    font-size: 12px;
                    transform: translateY(0); } } } }

    &.is-valid {
        color: $lime !important;
        border-color: $lime !important; }
    &.is-invalid {
        color: $red !important;
        border-color: $red !important; }

    &::-ms-expand {
        background-color: transparent;
        border: 0; }

    &::placeholder {
        color: $gray; }

    &:focus {
        border-color: $weed;
        &::placeholder {
            color: transparent; } } }

.form-text {
    height: 235px; }

textarea.input {
    padding-top: 20px;
    & + .input-label {
        top: 25px;
        background: #fff; }
    &.input-has-val,
    &:focus {
        & + .input-label {
            top: 1px !important;
            padding-top: 5px;
            padding-bottom: 5px;
            z-index: 1; } } }

.invalid-feedback {
    font-size: 14px;
    display: none;
    color: #fff; }

.checkbox {
    display: block;
    padding-left: 32px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    transition: all .3s;

    &-custom {
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid $red;
        border-radius: 2px;
        transition: background-color .3s;
        position: absolute;
        top: 3px;
        left: 0; }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked ~ .checkbox-custom {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 10px; } }

    &:hover {
 } }        // color: $c-10


.form-error {
    font-size: 14px;
    color: $red; }

.b-form {
    &_h-100vh {
        min-height: 100vh; }
    &::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        background-color: rgba(34, 34, 34, .7); } }
.form {
    &_max-w {
        max-width: 1014px; } }



@include media-breakpoint-down(sm) {
    .form-text {
        height: 160px !important; } }

@include media-breakpoint-down(xs) {
    .input {
        height: 50px;
        padding-bottom: 1px; } }
