.b-about {
    padding-bottom: 180px; }
.about {
    &-bg-content {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        width: calc(50% + 148px); }
    &-content {
        padding: 100px 0 100px 210px; }
    &-row {
        min-height: 100vh; }
    &-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc(50% - 148px); }
    &__head {
        padding: 38px 88px 44px; }
    &__text {
        line-height: 1.8;
        font-size: 18px;
        p {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0; } } }
    &-box {
        position: absolute;
        margin-left: -30px;
        top: 0;
        height: 100%;
        width: 42.2%;
        &-col {
            position: static; }
        &__bg {
            top: 0;
            right: 0;
            bottom: 0;
            position: absolute; }
        &__link {
            left: 0;
            bottom: 0;
            opacity: 0;
            font-size: 18px;
            position: absolute;
            text-decoration: none !important;
            background-color: rgba(255,255,255,.5);
            &:hover {
                background-color: $lime; } } } }

.aos-animate {
    & ~ .about-box__link {
        animation: showIn 1s forwards 2.5s; } }


@media (min-width: 1760px) {
    .mb-xxl-155 {
        margin-bottom: 155px; } }

@media (max-width: 1759px) {
    .about-row {
        min-height: 0; }
    .about-content {
        padding: 60px 0 60px 120px; }
    .about-bg-content {
        width: calc(50% + 80px); }
    .about-bg {
        width: calc(50% - 80px); }
    .b-about {
        padding-bottom: 140px; }
    .about__head {
        padding: 30px 68px 32px; } }

@media (max-width: 1599px) {
    .about-content {
        padding: 60px 0 60px 60px; }
    .about-bg {
        width: calc(50% - 30px); }
    .about-bg-content {
        width: calc(50% + 30px); }
    .b-about {
        padding-bottom: 120px; }
    .about__head {
        padding: 25px 55px 25px;
        margin-right: -15px; }
    .about__text {
        font-size: 16px; }
    .about-box {
        width: 40.7%;
        margin-left: -15px; } }
@include media-breakpoint-up(lg) {
    .about__text {
        max-width: 750px; }
    .about {
        &-col {
            flex: 0 0 60.4%;
            max-width: 60.4%; } } }

@media (min-width: 1024px) and (max-width: 1359px) {
    .about-title {
        font-size: 34px !important; } }

@include media-breakpoint-down(lg) {
    .about-content {
        padding: 0 0 60px; }
    .about-bg {
        position: static;
        height: 400px; }
    .b-about {
        padding-bottom: 100px; }
    .about__text {
        font-size: 15px; }
    .about__head {
        padding: 20px 40px 20px; } }

@include media-breakpoint-down(md) {
    .b-about {
        padding-bottom: 80px; }
    .about-box__link {
        opacity: 1;
        animation: none !important; }
    .about__head {
        margin-right: 0;
        animation: none !important;
        transform: none; }
    .about__text {
        font-size: 15px;
        max-width: 576px;
        margin: 0 auto 20px; }
    .about-box {
        height: 320px;
        max-width: 576px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        &__bg {
            animation: none !important;
            top: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            left: 0;
            width: 100%;
            transform: none; } } }

@include media-breakpoint-down(sm) {
    .b-about {
        padding-bottom: 60px; } }
@include media-breakpoint-down(xs) {
    .b-about {
        padding-bottom: 50px; }
    .about__head {
        padding: 15px 30px 15px; } }
