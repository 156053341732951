@each $color, $value in $color-custom {
	.link-#{$color} {
		color: $value !important; } }

.link-decor {
	text-decoration: underline;
	&:hover {
		text-decoration: none; } }

.link {
	text-decoration: none !important;
	color: $weed;
	&:hover {
		color: $lime; } }
