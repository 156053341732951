.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative; }

.dropdown-toggle {
	white-space: nowrap; }


.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: $dropdown-min-width;
	padding: $dropdown-padding-y 0;
	margin-top: -80px;
	list-style: none;
	background-color: $dropdown-bg;
	background-clip: padding-box;
	box-shadow: 0px 4px 30px rgba(0, 27, 71, 0.06);
	transition: all .3s;
	opacity: 0;
	visibility: hidden;
	z-index: -1;

	&.show {
		margin: $dropdown-spacer 0 0;
		opacity: 1;
		visibility: visible;
		z-index: $zindex-dropdown; } }


@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.dropdown-menu#{$infix}-left {
			right: auto;
			left: 0; }

		.dropdown-menu#{$infix}-right {
			right: 0;
			left: auto; } } }


.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: $dropdown-spacer; }

	.dropdown-toggle {
		@include caret(up); } }


.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer; }

  .dropdown-toggle {
    @include caret(right);

    &::after {
      vertical-align: 0; } } }


.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer; }

  .dropdown-toggle {
    @include caret(left);
    &::before {
      vertical-align: 0; } } }


.dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto; } }


.dropdown-divider {
  @include nav-divider($dropdown-divider-bg, $dropdown-divider-margin-y); }


.dropdown-item {
  display: block;
  width: 100%;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  color: $dropdown-link-color;
  text-align: inherit;
  background-color: transparent;
  border: 0;


  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius); }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius); } }


  @include hover-focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-hover-bg); }


  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-active-bg); }


  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;

    @if $enable-gradients {
      background-image: none; } } }


// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  margin-bottom: 0;
  @include font-size($font-size-sm);
  color: $dropdown-header-color;
  white-space: nowrap; }

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color; }


//  Responsive Media Querries  -----------------------------------------------------------

@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {

	.dropdown-menu {
		display: none;

		&.show {
			display: block; } } }


@include media-breakpoint-down(sm);


@include media-breakpoint-down(xs) {

	.dropdown-xs-s {

		.dropdown-menu {
			width: 100% {
      position: static !important;
      transform: none !important; } } } }
