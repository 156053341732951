.docs {
    &-nav {
        width: 445px;
        &__link {
            padding: 10px 30px 10px 20px;
            min-height: 60px;
            line-height: 1.3;
            font-size: 24px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 700;
            border-bottom: 1px solid $weed;
            text-decoration: none !important;
            background: url('../img/icon_chevron_top_weed.svg') no-repeat right 18px center;
            background-size: 13px 8px;
            &.collapsed {
                background-image: url('../img/icon_chevron_bottom_weed.svg'); }
            &:hover {
                color: $lime; } } } }

.doc {
    padding: 35px 45px;
    &-name {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.3;
        flex: 0 0 auto;
        text-decoration: none !important;
        &:hover {
            color: $lime; } }
    &-text {
        font-size: 16px;
        padding-top: 26px;
        line-height: 1.58;
        flex: 1 0 auto; }
    &-more {
        letter-spacing: 1px;
        text-decoration: none !important;
        &:hover {
            color: $lime; } } }

.price {
    &-nav__link {
        padding: 10px 30px 10px 35px;
        min-height: 60px;
        line-height: 1.3;
        font-size: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid #efefef;
        text-decoration: none !important;
        background: url('../img/icon_chevron_top_weed.svg') no-repeat right 18px center;
        background-size: 10px auto;
        &.collapsed {
            background-image: url('../img/icon_chevron_bottom_weed.svg'); }
        &:hover {
            color: $lime;
            background-image: url('../img/icon_chevron_top_lime.svg');
            &.collapsed {
                background-image: url('../img/icon_chevron_bottom_lime.svg'); } } }

    &-table {
        &-name {
            padding: 10px 15px 10px 55px;
            font-size: 20px;
            border-bottom: 1px solid #efefef; }
        &-cost {
            white-space: nowrap;
            padding: 10px 55px 10px 15px;
            font-size: 22px;
            font-weight: 600;
            border-bottom: 1px solid #efefef; } } }

.collapse-inner {
    border-bottom: 1px solid #e4e4e4;
    &-list {
        margin-bottom: 0;
        li {
            a {
                font-size: 18px;
                font-weight: 600;
                line-height: 1.3;
                display: block;
                padding: 7px 20px 7px 30px;
                text-decoration: none !important;
                &.active,
                &:hover {
                    color: $lime; } } } } }

.old-prize {
    color: #9E9E9E;
    text-decoration: line-through; }

@media (min-width: 1600px) {
    .docs-nav {
        margin-right: 60px; } }
@media (max-width: 1599px) {
    .docs-nav {
        width: 360px; }
    .doc {
        padding: 25px 30px; } }

@include media-breakpoint-up(lg);
@include media-breakpoint-down(lg) {
    .doc {
        padding: 25px 20px; }
    .docs-nav {
        width: 100%; } }
@include media-breakpoint-down(md) {
    .price-table-name {
        padding: 6px 15px 6px 35px;
        font-size: 18px; }
    .price-nav__link {
        padding: 8px 20px 8px 25px;
        min-height: 50px;
        font-size: 18px; }
    .price-table-prize {
        padding: 10px 15px 10px 10px;
        font-size: 19px; } }
@include media-breakpoint-down(sm) {
    .doc-name {
        font-size: 22px; } }

@include media-breakpoint-down(xs) {
    .price-table-prize {
        padding: 10px 0 10px 10px;
        font-size: 17px; }
    .price-table-name {
        padding: 6px 10px 6px 0;
        font-size: 16px; }
    .price-nav__link {
        padding: 8px 20px 8px 0;
        background-position: right center; }
    .doc-text {
        font-size: 15px;
        padding-top: 15px; }
    .collapse-inner-list li a {
        font-size: 16px;
        padding: 5px 15px 5px 20px; }
    .docs-nav__link {
        font-size: 19px;
        min-height: 50px;
        padding: 7px 25px 7px 0;
        background-position: right center;
        font-size: 20px; }
    .doc {
        padding: 20px 15px 25px; } }
