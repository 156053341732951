@import "../libs/bootstrap/scss/functions";
@import "../libs/bootstrap/scss/variables";
@import "../libs/bootstrap/scss/mixins";

// $theme-colors: map-remove($theme-colors,
//     "primary",
//     "secondary",
//     "success",
//     "info",
//     "warning",
//     "danger",
//     "light",
//     "dark"
// )

// @import "../libs/bootstrap/scss/root"
@import "../libs/bootstrap/scss/reboot";
@import "../libs/bootstrap/scss/type";
@import "../libs/bootstrap/scss/grid";
@import "bootstrap/dropdown";
// @import "../libs/bootstrap/scss/buttons"
// @import "../libs/bootstrap/scss/forms"
// @import "../libs/bootstrap/scss/custom-forms"
// @import "../libs/bootstrap/scss/nav"
// @import "../libs/bootstrap/scss/navbar"
// @import "../libs/bootstrap/scss/tables"
@import "../libs/bootstrap/scss/modal";
@import "bootstrap/modal";
// @import "../libs/bootstrap/scss/close"
@import "../libs/bootstrap/scss/transitions";
// @import "../libs/bootstrap/scss/input-group"
// @import "../libs/bootstrap/scss/button-group"
// @import "../libs/bootstrap/scss/badge"
// @import "../libs/bootstrap/scss/card"
// @import "../libs/bootstrap/scss/media"
// @import "../libs/bootstrap/scss/breadcrumb"
// @import "../libs/bootstrap/scss/pagination"
// @import "../libs/bootstrap/scss/alert"
// @import "../libs/bootstrap/scss/toasts"
// @import "../libs/bootstrap/scss/tooltip"
// @import "../libs/bootstrap/scss/images"
// @import "../libs/bootstrap/scss/utilities"
@import "../libs/bootstrap/scss/utilities/embed";

// @import "../libs/bootstrap/scss/vendor/rfs"
