.b-header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    position: fixed;
    padding-top: 22px;
    padding-bottom: 22px;
    transition: all .3s;
    &_page {
        background: #fff;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1); }
    &_scroll {
        padding-top: 14px;
        padding-bottom: 14px;
        background-color: #fff;
        box-shadow: 0 0 5px 4px rgba(0,0,0,.07);
        .header-text {
            min-width: 163px; }
        .logo-img {
            height: 34px; }
        .scroll-d-n {
            display: none; }
        .header-menu-row {
            margin-bottom: 0; } } }

.header {
    &-text {
        font-size: 18px;
        min-width: 204px;
        letter-spacing: .5px; }
    &-link {
        font-size: 17px;
        margin-left: 15px;
        margin-right: 15px;
        font-weight: 700;

        text-transform: uppercase;
        text-decoration: none !important;
        &:hover {
            color: $lime; } } }




@keyframes shiftUp {
    from {
        opacity: 0;
        transform: translateY(10px); }
    to {
        opacity: 1;
        transform: translateY(0); } }

body header.b-header {
    .hamburger {
        width: 30px;
        height: 30px;
        padding: 0;
        display: block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        text-transform: none;
        background-color: transparent;
        border: 0;
        &-box {
            width: 100%;
            height: 100%;
            display: block;
            position: relative; }
        &-inner {
            width: 100%;
            height: 2px;
            left: 0;
            top: 15px;
            background-color: $lime !important;
            border-radius: 2px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
            &:before, &:after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: $lime !important;
                border-radius: 2px;
                position: absolute;
                transition-property: transform;
                transition-duration: 0.15s;
                transition-timing-function: ease;
                transition-property: none; }
            &:after {
                top: -10px; }
            &:before {
                bottom: -10px; } } } }

.link-aside {
    top: 50%;
    text-decoration: none !important;
    position: fixed;
    right: 0;
    z-index: 10;
    background: $lime;
    &:hover {
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1); } }

body.menu-open {
    .hamburger-inner {
        transform: rotate(45deg);
        &:after {
            opacity: 0; }

        &:before {
            bottom: 0;
            transform: rotate(-90deg); } } }

@keyframes fadeMe {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@keyframes fadeUpMe {
    from {
        opacity: 0;
        transform: translateY(10px); }
    to {
        opacity: 1;
        transform: translateY(0); } }


@media (max-width: 1599px) {
    .b-header {
        padding-top: 16px;
        padding-bottom: 16px; }
    .logo-img {
        height: 40px; }
    .header-link {
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px; }
    .header {
        &-text {
            min-width: 180px;
            font-size: 16px; } } }

@include media-breakpoint-up(xl) {
    .drop {
        position: static;
        &:hover {
            .header-link_drop:before,
            .dropbox {
                display: block;
                animation: shiftUp .4s forwards; } }
        &box {
            opacity: 0;
            padding-top: 20px;
            display: none;
            position: absolute;
            transform: translateY(10px);
            left: 0;
            right: 0;
            text-decoration: none !important;
            &-inner {
                columns: 2;
                column-gap: 30px;
                background-color: #fff; } } }
    .header-link_drop {
        padding-right: 13px;
        position: relative;
        &::after {
            width: 8px;
            height: 5px;
            position: absolute;
            content: '';
            right: 0;
            top: 50%;
            display: block;
            margin-top: -2px;
            background: url('../img/icon_arrow_down_weed.svg') no-repeat center;
            background-size: 100%; }
        &::before {
            opacity: 0;
            display: none;
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: #0000 #0000 #fff #0000;
            top: 100%;
            margin-top: 14px;
            right: -4px; }
        &:hover {
            &::after {
                background-image: url('../img/icon_arrow_down_lime.svg'); } } } }
@include media-breakpoint-down(lg) {
    .dropbox {
        display: none !important; }
    .header-menu {
        left: 0;
        right: 0;
        top: 72px;
        height: calc(100vh - 72px);
        opacity: 0;
        display: none;
        position: fixed;
        overflow-y: auto;
        background-color: #fff; }
    .header-link {
        display: inline-block;
        padding-bottom: 10px;
        padding-top: 10px; }
    .icon-phone {
        width: 28px; }
    .b-header {
        transition: background-color 0s;
        &::before {
            display: none;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            background-color: #fff; }
        &_scroll {
            .header-menu {
                top: 66px;
                height: calc(100vh - 66px); } } }
    body.menu-open {
        overflow: hidden;
        .header-link {
            opacity: 0;
            transform: translateY(10px);
            animation: fadeUpMe .5s forwards .5s; }
        &::before {
            opacity: 1; }
        .b-header {
            &::before {
                display: block;
                opacity: 1;
                animation: fadeMe .5s forwards; } }
        .header-menu {
            display: block;
            opacity: 1;
            animation: fadeMe .5s forwards; } } }

@include media-breakpoint-down(md) {
    .b-header {
        padding-top: 12px !important;
        padding-bottom: 12px !important; }
    .header-menu {
        top: 60px !important;
        height: calc(100vh - 60px) !important; }
    .logo-img {
        height: 36px !important; }
    .header-link {
        padding-bottom: 8px;
        padding-top: 8px; } }

@include media-breakpoint-down(sm);

@include media-breakpoint-down(xs) {
    html body a.bvi-link-widget .bvi-svg-eye,
    html body a.bvi-link-shortcode .bvi-svg-eye {
        width: 24px; }
    html body a.bvi-link-widget,
    html body a.bvi-link-shortcode {
        padding: 0; }
    .hamburger {
        width: 24px; }
    .header-menu {
        top: 54px !important;
        height: calc(100vh - 54px) !important; }
    .logo-img {
        height: 26px !important; } }
