body {
	min-width: 320px;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important; }

.b-main {
	flex: 1 0 auto;
	padding-top: 182px; }

.pos-r {
	position: relative; }
.pos-a {
	position: absolute; }
.pos-s {
	position: static; }
.pos-f {
	position: fixed; }

.img-fluid {
	max-width: 100%;
	height: auto; }

.text-truncate {
	overflow: hidden;
	white-space: nowrap {
  	text-overflow: ellipsis; } }

*:focus {
	outline: 0 !important; }

a,
.btn,
.form-input {
	transition: $transition-base; }

.content {
	font-size: 18px;
	line-height: 1.6;

	p {
		text-align: justify; }

	img {
		max-width: 100%;
		height: auto;
		&.float-right {
			max-width: 50%;
			margin-bottom: 10px;
			margin-left: 30px; }
		&.float-left {
			max-width: 50%;
			margin-bottom: 10px;
			margin-right: 30px; } }

	ul:not([class]) {
		list-style: none;
		padding-left: 0;
		margin-top: 10px;
		margin-bottom: 10px;

		> li {
			padding-left: 20px;
			margin-left: 0;
			margin-bottom: 10px;
			position: relative;

			&:before {
				content: '•';
				color: $weed;
				line-height: 1;
				font-size: 18px;
				position: absolute;
				top: 4px;
				left: 0; } } }

	ol:not([class]) {
		counter-reset: li;
		padding-left: 0;
		margin-top: 10px;
		margin-bottom: 10px;

		> li {
			list-style: none;
			padding-left: 20px;
			margin-bottom: 10px;
			position: relative;

			&:before {
				content: counter(li)".";
				counter-increment: li;
				font-weight: 700;
				color: $weed;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1; } } }

	a {
		text-decoration: underline;
		color: $link-color;

		&:hover {
			text-decoration: none; } }

	.btn {
		text-decoration: none; }

	p:last-child,
	ul:last-child,
	ol:last-child {
		margin-bottom: 0; }

	p {
		margin-bottom: 10px; }

	h2, h3, h4, h5, h6, h1 {
		color: $weed;
		margin-top: 25px;
		margin-bottom: 15px; }
	h2 {
		font-size: 30px; } }

.nowrap {
	white-space: nowrap; }

.of-h {
	overflow: hidden; }

.container_xxl {
	max-width: 1768px; }

.container_xl {
    max-width: 1719px;
    padding: 0 90px; }

.title {
	color: $weed;
	font-weight: 700;
	font-size: 48px;
	padding: 0 30px 5px 30px;
	background: url('../img/icon_leaf_plus.svg') no-repeat right bottom; }

.card {
	&-top {
		height: 358px;
		&__img {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			transition: all .3s; } }
	&:hover {
		.card-top__img {
			transform: scale(1.1); } } }

.link-scroll-down {
	left: 50%;
	z-index: 1;
	width: 24px;
	height: 40px;
	bottom: 60px;
	border-radius: 20px;
	border: 2px solid $gray-light;
	transform: translateX(-50%);
	position: absolute;
	&::before {
		bottom: 7px;
		height: 8px;
		width: 3px;
		content: '';
		left: 50%;
		border-radius: 4px;
		margin-left: -1px;
		position: absolute;
		background-color: $gray-light; }
	&::after {
		top: 100%;
		left: 50%;
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		display: block;
		margin-top: 7px;
		margin-left: -6px;
		border-style: solid;
		border-width: 6px 6px 0 6px;
		border-color: $gray-light transparent transparent transparent;
		animation: upAndDown 1s infinite; }
	&:hover {
		border-color: $lime;
		&::before {
			background-color: $lime; }
		&::after {
			border-color: $lime transparent transparent transparent; } } }

.float-left {
	float: left !important; }
.float-right {
	float: right !important; }


@keyframes upAndDown {
	from {
		margin-top: 7px; }
	50% {
		margin-top: 13px; }
	to {
		margin-top: 7px; } }

.z-1 {
	z-index: 1; }

.scroll-menu {
	top: 50%;
	z-index: 10;
	right: 50%;
	position: fixed;
	margin-right: 846px;
	transform: translateY(-50%);
	&__link {
		width: 24px;
		height: 24px;
		position: relative;
		display: block;
		margin-top: 5px;
		margin-bottom: 5px;
		border-radius: 100%;
		border: 1px solid transparent;
		&::after {
			width: 8px;
			height: 8px;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			opacity: .5;
			background-color: $gray-light; }
		&.active {
			border-color: $gray-light !important;
			&::after {
				opacity: 1; } } } }

.section {
	min-height: 100vh; }

.error-box {
	&::after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background-color: rgba(34, 34, 34, .7);
		position: absolute; } }


.map {
	height: 100%;
	width: calc((100vw - 1818px) / 2 + 100%);
	position: absolute;
	&-wrapper {
		height: 600px; } }

.contact {
	width: 312px;
	letter-spacing: .8px;
	&-img {
		width: 40px; } }

.pagination {
    &-link {
        color: $weed;
        width: 60px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
        background-size: 14px auto;
        background-repeat: no-repeat;
        background-position: center;
        &.active,
        &:hover,
        &:focus {
            color: #fff;
            background-color: $lime; }
        &_prev {
            background-image: url('../img/icon_chevron_left_weed.svg');
            &:hover,
            &:focus {
                background-image: url('../img/icon_chevron_left_white.svg'); } }
        &_next {
            background-image: url('../img/icon_chevron_right_weed.svg');
            &:hover,
            &:focus {
                background-image: url('../img/icon_chevron_right_white.svg'); } } } }

.breadcrumb {
	li:not(:last-child) {
		&::after {
			content: '/';
			margin-left: 5px;
			margin-right: 5px; } }
	&-link {
		color: $weed;
		text-decoration: none !important;
		&:hover,
		&:focus {
			color: $lime; } } }

.sertificate-link {
	&::after {
		content: '';
		width: 26px;
		height: 26px;
		position: absolute;
		left: 50%;
		margin-left: -13px;
		top: 100%;
		margin-top: 15px;
		background: url('../img/icon_zoom.svg') no-repeat center;
		background-size: 26px 26px; }
	&:hover,
	&:focus {
		&::after {
			background-image: url('../img/icon_zoom_lime.svg'); } } }

//  Responsive Media Querries  -----------------------------------------------------------

@media (max-width: 1818px) {
	.map {
		width: calc(100vw - 532px);
		&-wrapper {
			overflow: hidden; } } }

@media (max-width: 1759px) {
	.scroll-menu {
		left: 15px;
		right: auto;
		margin-right: 0; } }

@media (max-width: 1599px) {
	.link-scroll-down {
		bottom: 30px; }
	.title {
		font-size: 46px; } }

@include media-breakpoint-down(lg) {
	.map {
		width: calc(100vw - 381px); }
	.contact {
		width: 282px; }
	.contact-img {
		width: 26px; }
	.b-main {
		padding-top: 124px; }
	.title {
		font-size: 42px; }
	.card-top {
		height: 258px; }
	.container_xl {
	    padding: 0 64px; } }
@include media-breakpoint-down(md) {
	.content h2 {
		font-size: 26px; }
	.error-img {
		width: 300px; }
	.b-main {
		padding-top: 100px; }
	.map-wrapper {
		height: 480px; }
	.container_xl {
		padding: 0 15px; }
	.title {
		font-size: 38px; } }
@include media-breakpoint-down(sm) {
	.content img.float-left {
		max-width: 100%;
		float: none;
		display: block;
		margin-right: 0; }
	.content img.float-right {
		max-width: 100%;
		float: none;
		display: block;
		margin-right: 0;
		margin-left: 0; }
	.content {
		font-size: 16px; }
	.content h2 {
		font-size: 24px; }
	.pagination-link {
		width: 40px;
		height: 40px;
		background-size: 10px auto; }
	.contact {
		width: 100%; }
	.map {
		left: 0;
		width: 100%;
		&-wrapper {
			height: 400px; } }
	.title {
		font-size: 34px;
		padding: 0 30px 5px;
		background-size: 42px; }
	.card-top {
		height: 300px; } }
@include media-breakpoint-down(xs) {
	.content ul:not([class]) > li:before {
		top: 1px; }
	.content {
		font-size: 14px; }
	.content h2 {
		font-size: 22px; }
	.error-img {
		width: 200px; }
	.b-main {
		padding-top: 85px; }
	.map {
		&-wrapper {
			height: 280px; } }
	.card {
		max-width: 300px; }
	.title {
		font-size: 28px;
		padding: 0 20px 5px;
		background-size: 34px; }
	body {
		line-height: 1.4; }

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
		line-height: 1.2; } }
